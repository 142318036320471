<template>
  <div class="content">
    <base-header />
    <main class="wrapper">
      <slot />
    </main>
    <base-footer />
    <div
      v-show="isFloatingButt"
      ref="floatButtRef"
      class="layout-float-butt animate__animated animate__flipInY"
    >
      <base-button
        :text="buttonText"
        size="sg"
        variant="prime"
        style="opacity: 0.6"
        @click="buttonAction"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PopUpServices } from "~/services/PopUp/callPopUp";
import { getLocalStorage } from "~/services/LocalStorage/localStorage";
import eventBus from "~/utilities/composables/eventBus";
import { userStore } from "~/store/user";

const callPopUp = PopUpServices();
const useUserStore = userStore();

const floatButtRef = ref<HTMLElement | null>(null);
const isFloatingButt = ref(false);
const buttonText = ref("");
const buttonAction = ref<() => void>(() => {});

let destroyTimeout: any;

type CallFixedActionButtHandler = (event: {
  text: string;
  action: () => void;
  timeOut?: number;
  position?: { x: number; y: number };
}) => void;

const callFloatButton: CallFixedActionButtHandler = async ({
  text,
  action,
  timeOut,
  position,
}) => {
  if (isFloatingButt.value) {
    await destroyFloatButton(0);
  }
  buttonText.value = text;
  buttonAction.value = action;
  isFloatingButt.value = true;
  const buttPosition = position ? position : { x: 30, y: 30 };
  floatButtRef.value!.style.left = `${buttPosition.x - 70}px`;
  floatButtRef.value!.style.top = `${buttPosition.y + 50}px`;
  document.addEventListener("scroll", destroyButtOnScroll);
  await destroyFloatButton(timeOut);
};

const destroyFloatButton = (timeOut: number | undefined): Promise<void> => {
  if (destroyTimeout) {
    clearTimeout(destroyTimeout);
  }

  return new Promise((resolve) => {
    destroyTimeout = setTimeout(
      () => {
        floatButtRef.value?.classList.add("animate__flipOutY");
        setTimeout(() => {
          isFloatingButt.value = false;
          floatButtRef.value?.classList.remove("animate__flipOutY");
          resolve();
        }, 500);
      },
      typeof timeOut === "number" ? timeOut : 3000
    );
  });
};

const destroyButtOnScroll = () => {
  destroyFloatButton(0);
  document.removeEventListener("scroll", destroyButtOnScroll);
};

const checkCookie = () => {
  const isCookie = getLocalStorage("cookie_agreement");
  if (!isCookie) {
    callPopUp.callPopUp({
      componentName: "base-cookie-pop-up",
      persistent: true,
    });
  }
};

const checkUser = () => {
  const token = useCookie("acc_token");
  if (token.value) {
    useUserStore.fetchUserData();
  }
};

onMounted(() => {
  eventBus.on("call-fixed-action-butt", callFloatButton);
  checkCookie();
  checkUser();
});
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white-monochrome);
  overflow-x: hidden;
  .layout-float-butt {
    position: fixed;
    // right: 30px;
    // bottom: 30px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
